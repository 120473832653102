.root {
  text-align: center;
}

.title {
  max-width: 732px;
  margin: 0 auto;
  font-size: 36px;
  line-height: 1.28;
  font-weight: 700;
}

.subtitle {
  max-width: 732px;
  margin: 15px auto 0;
  font-weight: 400;
  line-height: 1.28;
  color: var(--grey-0);
}

.requestForm {
  max-width: 732px;
  margin: 48px auto 0;
}

.buttons {
  margin-top: 32px;
  justify-content: center;
  gap: 8px;
}

.button {
  min-height: 40px;
  padding: 8px 16px;
  border-radius: var(--border-radius-s);
  font-size: 15px;
  font-weight: 500;
  gap: 8px;
}

.secondary {
  box-shadow: inset 0 0 0 1px var(--grey-3);
}

.notice {
  margin-top: 8px;
  font-size: 14px;
  color: var(--grey-0);
}

.buttons {
  margin-top: 60px;
}

.videoWrap {
  position: relative;
  margin-top: 40px;
  border-radius: 12px;
  overflow: hidden;
}

.videoOverlay {
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #00000026;
}

.fillingScreen {
  width: 100%;
  height: auto;
}

.topButton:hover,
.topButton:focus-visible {
  color: var(--color-green-primary);
  background: var(--color-green-4);
  box-shadow: inset 0 0 0 1px var(--color-green-primary);
}

@media (max-width: 1024px) {
  .video {
    height: 382px;
  }

  .fillingScreen {
    border-radius: var(--border-radius-s);
  }
}

@media (max-width: 800px) {
  .video {
    height: 292px;
  }

  .fillingScreen {
    border-radius: var(--border-radius-xs);
  }
}

@media (max-width: 575px) {
  .root {
    padding-top: 20px;
  }

  .title {
    font-size: 24px;
  }

  .subtitle {
    font-size: 16px;
  }

  .video {
    height: 240px;
  }

  .fillingScreen {
    border-radius: var(--border-radius-xxs);
  }

  .buttons {
    flex-direction: row;
    margin-top: 24px;
  }

  .mediaSlider {
    margin-top: 24px;
  }

  .notice {
    margin-top: 16px;
  }
}
