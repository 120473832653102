.root {
  padding: 0;
}

.content {
  display: flex;
  justify-content: space-between;
  background: var(--gray-100);
  border-radius: var(--border-radius-m);
  padding: 32px;
}

.item {
  width: 30%;
  text-align: center;
}

.title {
  margin-bottom: 8px;
  font-weight: 700;
  font-size: 24px;
  line-height: 1;
}

.title span {
  font-size: 40px;
}

.description {
  color: var(--gray-600);
  line-height: 1.3;
}

@media (max-width: 1024px) {
  .item {
    width: 32%;
  }

  .title {
    font-size: 20px;
  }

  .title span {
    font-size: 32px;
  }
}
