.root {
  background: linear-gradient(96.97deg, #FFFFFF 45.2%, #B4DCAF 95.76%);
  overflow: hidden;
}

.content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 9%;
}

.title {
  margin: 0 0 15px;
  font-size: 24px;
  font-weight: 700;
  line-height: 1.33;
}

.description {
  margin-bottom: 32px;
  font-size: 18px;
  line-height: 1.28;
  color: var(--grey-0);
}

.left {
  flex-shrink: 0;
  width: 47%;
}

.fields {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;

  --input-height: 40px;
  --input-radius: 10px;
  --input-padding-y: 8px;
  --input-font-size: 14px;
}

.inputContainer {
  flex: 1 1 45%;
}

.inputContainer:last-child {
  flex: 1 1 100%;
}

.inputContainer input {
  background: var(--color-white-primary);
}

.buttons {
  margin-top: 16px;
}

.button {
  padding: 6px;
  min-height: 40px;
  font-weight: 500;
  font-size: 15px;
}

.notice {
  margin: 10px auto 0;
  font-size: 12px;
  line-height: 1.2;
  color: var(--grey-2);
}

.notice a {
  color: inherit;
  text-decoration: underline;
}

.resultTitle {
  margin-right: 50px;
  font-size: 24px;
  line-height: 1.28;
  font-weight: 700;
}

.resultSubtitle {
  margin-top: 32px;
  font-size: 18px;
  line-height: 1.28;
}

@media (max-width: 1024px) {
  .content {
    flex-direction: column;
  }

  .left {
    width: 100%;
  }
}

@media (max-width: 575px) {
  .root {
    background: linear-gradient(44.25deg, #FFFFFF 40.75%, #B4DCAF 87.89%);
  }

  .inputContainer {
    flex: 1 1 100%;
  }

  .resultTitle {
    margin-right: 0;
    font-size: 18px;
  }

  .resultSubtitle {
    margin-top: 24px;
    font-size: 14px;
    line-height: 1.28;
  }
}
