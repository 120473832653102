.container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.content {
  max-width: 564px;
}

.title {
  margin: 0 0 15px;
  font-size: 32px;
  line-height: 1.3;
}

.description {
  line-height: 1.3;
  color: var(--gray-600);
}

.buttons {
  margin-top: 32px;
}

.button {
  min-height: 40px;
  padding: 6px 16px;
  font-size: 15px;
  font-weight: 500;
}

.image {
  border-radius: 4px;
  box-shadow: 0 4px 4px -4px #0C0C0D0D, 0 16px 32px -4px #0C0C0D1A;
}

@media (max-width: 1024px) {
  .title {
    font-size: 28px;
  }
}

@media (max-width: 800px) {
  .container {
    flex-direction: column;
    gap: 32px;
  }

  .description {
    font-size: 16px;
  }

  .image {
    width: 100%;
    height: auto;
  }

  .buttons {
    margin-top: 0;
  }
}

@media (max-width: 575px) {
  .title {
    font-size: 24px;
  }
}
