.container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.option {
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
}

.input {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  margin: 2px;
  padding: 0;
  opacity: 0;
  visibility: hidden;
  z-index: -1;
}

.icon {
  flex-shrink: 0;
  position: relative;
  margin: 2px;
  width: 20px;
  height: 20px;
  border: 2px solid #ACBAC199;
  border-radius: 20px;
}

.icon.checked {
  border-color: var(--primary-color);
}

.icon.checked::after {
  content: '';
  position: absolute;
  inset: 3px;
  background: var(--primary-color);
  border-radius: 20px;
}
