.root {
  padding: 80px 0;
}

.white {
  background: var(--white);
}

.green {
  background: #66AD5C;
  color: var(--white);
}

.title {
  margin-top: 0;
  margin-bottom: 45px;
  font-weight: 700;
  font-size: 32px;
  line-height: 1.25;
}

@media (max-width: 1024px) {
  .root {
    padding: 70px 0;
  }

  .title {
    margin-bottom: 28px;
    font-size: 28px;
  }
}

@media (max-width: 800px) {
  .root {
    padding: 60px 0;
  }
}

@media (max-width: 575px) {
  .root {
    padding: 50px 0;
  }

  .title {
    margin-bottom: 16px;
    font-size: 24px;
  }
}
