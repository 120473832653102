.root h3 {
  text-align: center;
}

.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
}

.block {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 16px;
  border-radius: var(--border-radius-l);
  box-shadow: var(--box-shadow-a);
  background-color: var(--color-white-primary);
  text-align: center;
  gap: 24px;
}

.blockTitle {
  font-size: 18px;
  line-height: 1.25;
  font-weight: 600;
}

.blockDescr {
  font-size: 14px;
  color: var(--grey-0);
}

.blockButton {
  min-height: 40px;
  padding: 8px 16px;
  font-size: 15px;
  font-weight: 500;
  border-radius: var(--border-radius-s);
  box-shadow: inset 0 0 0 1px var(--grey-3);
}

.icon {
  background-color: var(--color-white-primary);
}

@media (max-width: 1280px) {
  .grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 1024px) {
  .grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 800px) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 575px) {
  .root h3 {
    text-align: left;
  }

  .block {
    padding: 20px 22px;
  }

  .blockTitle {
    margin-bottom: 16px;
  }

  .slider {
    overflow: hidden;
    margin-left: calc(var(--container-padding)*-1);
    margin-right: calc(var(--container-padding)*-1);
    padding-left: 15px;
    padding-right: 15px;
  }

  .slider :global(.slick-list) {
    width: 72%;
    margin: 0 -5px;
    overflow: visible;
  }

  .slider :global(.slick-slide) {
    padding: 0 5px;
  }

  .slider img {
    margin-left: auto;
    margin-right: auto;
  }
}
