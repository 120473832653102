.root {
  --bg: var(--body-bg);
  --gap: 45px;
  position: relative;
  display: flex;
  overflow: hidden;
  user-select: none;
  gap: var(--gap);
}

.root::after {
  content: '';
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 1;
}

.root::after {
  right: 0;
  background: linear-gradient(270deg, var(--bg) 0%, transparent 7%, transparent 93%, var(--bg) 100%);
}

.list {
  flex-shrink: 0;
  display: flex;
  justify-content: space-around;
  min-width: 100%;
  gap: var(--gap);
  animation: scroll-x 60s linear infinite;
}

/* .root:hover .list {
  animation-play-state: paused;
} */

.icon {
  height: 52px;
}

@keyframes scroll-x {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(calc(-100% - var(--gap)));
  }
}

@media (max-width: 575px) {
  .icon {
    height: 44px;
  }
}
