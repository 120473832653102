.root {
  padding: 0;
  overflow: hidden;
}

.block {
  display: flex;
  align-items: center;
  padding: 50px 0;
  gap: 100px;
}

.block:nth-child(2n) {
  flex-direction: row-reverse;
}

.blockImage {
  flex-shrink: 0;
}

.blockContent {
  flex-grow: 1;
}

.blockTitle {
  margin-bottom: 24px;
  font-weight: 600;
  font-size: 24px;
  line-height: 1.25;
}

.blockDescr {
  line-height: 1.25;
  color: var(--gray-600);
}

.blockButtons {
  margin-top: 48px;
}

.blockButton {
  min-height: 40px;
  padding: 6px 16px;
  font-size: 15px;
  font-weight: 500;
}

.blockList {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.blockListItem {
  display: flex;
  margin: 0;
  padding: 0;
  gap: 12px;
}

.blockListItem:not(:last-child) {
  margin-bottom: 16px;
}

.blockListItemIcon {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  color: var(--primary-color);
}

.blockListItemIcon svg {
  width: 100%;
  height: 100%;
}

/* .blockList :global(.grey) {
  color: var(--grey-3);
}

.blockList :global(.green) {
  color: var(--color-green-primary);
} */

@media (max-width: 1280px) {
  .block {
    gap: 50px;
  }

  .blockImage {
    width: 60%;
  }
}

@media (max-width: 1024px) {
  .block {
    gap: 32px;
  }

  .blockImage {
    width: 50%;
  }
}

@media (max-width: 575px) {
  .root {
    padding-bottom: 40px;
  }

  .block {
    flex-direction: column;
    align-items: stretch;
    padding: 40px 0 0;
    font-size: 16px;
    gap: 32px;
  }

  .block:nth-child(2n) {
    flex-direction: column;
  }

  .blockImage {
    width: 100%;
  }

  .blockButton {
    width: 100%;
  }
}
