.container {
  padding: 0;
}

.content {
  display: flex;
}

.left {
  flex-grow: 1;
  padding: 24px;
}

.title {
  margin-bottom: 8px;
  font-size: 24px;
  font-weight: 700;
  line-height: 1.3;
}

.subtitle {
  margin-bottom: 24px;
  color: var(--grey-1);
  line-height: 1.3;
}

.buttons {
  margin-top: 16px;
}

.button {
  min-height: 40px;
  font-size: 15px;
  font-weight: 500;
  padding: 5px 14px;
  border-radius: var(--border-radius-s);
}

.notice {
  margin-top: 16px;
  font-size: 12px;
  line-height: 1.2;
  color: var(--grey-3);
  text-align: center;
}

.notice a {
  color: inherit;
  text-decoration: underline;
}

.fields {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.input {
  background: var(--color-white-primary);
  --input-radius: var(--border-radius-s);
  --input-height: 40px;
  --input-font-size: 14px;
}

.result {
  text-align: center;
}

.resultTitle {
  margin-bottom: 68px;
  font-family: var(--headings-font);
  font-weight: 700;
  font-size: 20px;
}

.resultSubtitle {
  padding: 17px 0;
  font-size: 18px;
  line-height: 1.25;
}

.right {
  flex-shrink: 0;
  width: 417px;
  padding: 24px;
  background: #E8ECED;
}

.ceo {
  display: flex;
  align-items: center;
  gap: 12px;
}

.ceoImage {
  border: 4px solid var(--white);
  border-radius: 100px;
}

.ceoName {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.23;
}

.ceoPosition {
  margin-top: 4px;
  line-height: 1.23;
  color: #8A8D8F;
}

.info {
  margin-top: 32px;
}

.infoTitle {
  margin-bottom: 24px;
  font-size: 20;
  font-weight: 600;
  line-height: 1.23;
  color: #4B4E4F;
}

.infoList {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.infoItem {
  display: flex;
  font-size: 14px;
  line-height: 1.25;
  color: #4B4E4F;
  gap: 12px;
}

.infoItem:not(:last-child) {
  margin-bottom: 16px;
}

.infoIcon svg {
  width: 20px;
  height: 20px;
  color: var(--primary-color);
}

@media (max-width: 1024px) {
  .right {
    width: 340px;
  }
}

@media (max-width: 800px) {
  .right {
    display: none;
  }
}

@media (max-width: 575px) {
  .container {
    padding: 20px var(--container-padding);
  }

  .left {
    padding: 0;
  }

  .fields {
    flex-direction: column;
    gap: 8px;
  }

  .fields>div {
    width: 100%;
    flex-basis: 100%;
  }

  .result {
    margin-top: 30px;
  }

  .resultTitle {
    margin-bottom: 36px;
    font-size: 16px;
    font-weight: 600;
  }

  .resultSubtitle {
    font-size: 14px;
    font-weight: 400;
  }

  .image {
    margin: 0;
    width: 100%;
    height: auto;
  }

  .fields {
    margin-top: 24px;
  }

  .subtitle {
    text-align: center;
  }

  .buttons {
    margin-top: 40px;
  }

  .notice {
    margin-top: 10px;
  }
}
