.root {
  text-align: center;
}

.root h2,
.root h3 {
  max-width: 920px;
  margin-left: auto;
  margin-right: auto;
  /* font-size: 28px; */
}

.subtitle {
  margin-top: -20px;
  margin-bottom: 40px;
  font-size: 1.125rem;
  font-weight: 400;
  color: var(--grey-1);
}

.button {
  min-height: 40px;
  font-size: 15px;
  font-weight: 500;
  padding: 5px 14px;
  border-radius: var(--border-radius-s);
}

.notice {
  max-width: 522px;
  margin: 10px auto 0;
  font-size: 12px;
  line-height: 1.2;
  color: var(--grey-0);
}

.notice a {
  color: inherit;
  text-decoration: underline;
}

.fields {
  display: flex;
  max-width: 522px;
  margin: 0 auto;
  gap: 8px;
}

.input {
  background: var(--color-white-primary);
  --input-radius: var(--border-radius-s);
  --input-height: 40px;
  --input-font-size: 14px;
}

.result {
  text-align: center;
}

.resultTitle {
  margin-bottom: 68px;
  font-family: var(--headings-font);
  font-weight: 700;
  font-size: 20px;
}

.resultSubtitle {
  padding: 17px 0;
  font-size: 18px;
  line-height: 1.25;
}

@media (max-width: 575px) {
  .fields {
    flex-direction: column;
    gap: 8px;
  }

  .fields>div {
    width: 100%;
    flex-basis: 100%;
  }

  .notice {
    margin-top: 20px;
    font-size: 12px;
    color: var(--grey-2);
  }

  .result {
    margin-top: 30px;
  }

  .resultTitle {
    margin-bottom: 36px;
    font-size: 16px;
    font-weight: 600;
  }

  .resultSubtitle {
    font-size: 14px;
    font-weight: 400;
  }
}
