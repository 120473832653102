.root {
  background: linear-gradient(95.46deg, #FFFFFF 27.18%, #B4DCAF 96.6%);
}

.container {
  display: flex;
  gap: 32px;
}

.content {
  flex-shrink: 0;
  width: 55%;
}

.form {
  padding: 24px;
  background: var(--color-white-primary);
  border-radius: var(--border-radius-l);
  box-shadow: 0 4px 4px -1px #0C0C0D0D, 0 4px 4px -1px #0C0C0D1A;
}

.title {
  margin: 0 0 16px;
  font-size: 32px;
  line-height: 1.18;
  font-weight: 700;
}

.subtitle {
  margin: 0 0 32px;
  padding: 0;
  color: var(--grey-0);
}

.list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.listItem {
  display: flex;
  line-height: 1.25;
  gap: 16px;
}

.listItem:not(:last-child) {
  margin-bottom: 16px;
}

.listItemIcon {
  line-height: 0;
}

.listItemIcon svg {
  width: 20px;
  height: 20px;
  color: var(--primary-color);
}

.fields {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;

  --input-height: 40px;
  --input-radius: 10px;
  --input-padding-y: 8px;
  --input-font-size: 14px;
}

.inputContainer {
  flex: 1 1 100%;
}

.inputContainerName,
.inputContainerPhone {
  flex: 1 1 45%;
}

.buttons {
  flex: 1 1 100%;
}

.notice {
  font-size: 12px;
  line-height: 1.2;
  color: var(--grey-2);
  text-align: center;
}

.notice a {
  color: inherit;
  text-decoration: underline;
}

.result {
  padding: 30px 40px;
  text-align: center;
}

.resultTitle {
  margin-bottom: 24px;
}

.resultImage {
  width: 100%;
  height: auto;
  border-radius: var(--border-radius-xxs);
  box-shadow: 0px 8.97px 10.77px 0px #00000059,
    0px 0px 7.18px 0px #00000026,
    0px 0px 17.27px 0px #79BE70B2;
}

.root :global(.forma-ui__social-select__items) {
  margin-left: auto;
}

.modal {
  --input-border: var(--grey-3);
  --input-hover-border: var(--grey-2);
  --input-height: 44px;

  text-align: center;
}

.modalSubtitle {
  margin-bottom: 20px;
  color: var(--grey-1);
}

@media (max-width: 1280px) {
  .content {
    width: 45%;
  }
}

@media (max-width: 1024px) {
  .container {
    flex-direction: column;
  }

  .content {
    width: 100%;
  }
}

@media (max-width: 575px) {
  .root {
    background: linear-gradient(167.09deg, #FFFFFF 24.17%, #EFF7EE 49.18%, #79BE70 95.3%);
  }

  .title {
    margin-bottom: 20px;
    font-size: 24px;
  }

  .subtitle {
    font-size: 16px;
  }

  .listItem {
    line-height: 1.285;
    gap: 8px;
  }

  .listItemIcon svg {
    width: 18px;
    height: 18px;
  }

  .form {
    padding: 16px;
  }

  .inputContainer {
    flex-basis: 100%;
  }

  .result {
    padding: 0;
  }

  .resultTitle {
    margin-bottom: 32px;
    font-size: 16px;
  }
}
