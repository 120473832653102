.container {
  display: flex;
  align-items: center;
  gap: 24px;
}

.title {
  margin-bottom: 8px;
  font-size: 24px;
  font-weight: 700;
  line-height: 1.3;
}

.subtitle {
  margin-bottom: 24px;
  color: var(--grey-1);
  line-height: 1.3;
}

.buttons {
  margin-top: 16px;
}

.button {
  min-height: 40px;
  font-size: 15px;
  font-weight: 500;
  padding: 5px 14px;
  border-radius: var(--border-radius-s);
}

.notice {
  margin-top: 16px;
  font-size: 10px;
  line-height: 1.4;
  color: var(--grey-3);
  text-align: center;
}

.notice a {
  color: inherit;
  text-decoration: underline;
}

.fields {
  display: flex;
  gap: 16px;
}

.input {
  background: var(--color-white-primary);
  --input-radius: var(--border-radius-s);
  --input-height: 40px;
  --input-font-size: 14px;
}

.result {
  text-align: center;
}

.resultTitle {
  margin-bottom: 68px;
  font-family: var(--headings-font);
  font-weight: 700;
  font-size: 20px;
}

.resultSubtitle {
  padding: 17px 0;
  font-size: 18px;
  line-height: 1.25;
}

.image {
  margin: 10px 0;
  box-shadow: 0px 0px 10px 0px #79BE70B2;
  border-radius: 6px;
}

@media (max-width: 1024px) {
  .image {
    width: 300px;
    height: auto;
  }
}

@media (max-width: 800px) {
  .container {
    flex-direction: column;
  }

  .image {
    margin: 0;
    width: 100%;
    height: auto;
  }

  .fields {
    margin-top: 24px;
  }
}

@media (max-width: 575px) {
  .fields {
    flex-direction: column;
    gap: 8px;
  }

  .fields>div {
    width: 100%;
    flex-basis: 100%;
  }

  .result {
    margin-top: 30px;
  }

  .resultTitle {
    margin-bottom: 36px;
    font-size: 16px;
    font-weight: 600;
  }

  .resultSubtitle {
    font-size: 14px;
    font-weight: 400;
  }

  .image {
    margin: 0;
    width: 100%;
    height: auto;
  }

  .fields {
    margin-top: 24px;
  }

  .subtitle {
    font-size: 16px;
    text-align: center;
  }

  .buttons {
    margin-top: 40px;
  }

  .notice {
    margin-top: 10px;
  }
}
