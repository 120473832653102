.root {
  padding-top: 80px;
  padding-bottom: 40px;
  /* background: var(--color-white-primary); */
}

.marquee {
  /* --bg: var(--color-white-primary); */
}

@media (max-width: 575px) {
  .root {
    padding-bottom: 20px;
  }
}
