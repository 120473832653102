.root h3 {
  text-align: center;
}

.items {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
}

.item {
  padding: 24px;
  background: var(--white);
  border-radius: var(--border-radius-m);
  box-shadow: 0px 0px 24.7px 0px #79BE70B2;
  cursor: pointer;
}

.icon {
  margin-bottom: 24px;
}

.title {
  margin-bottom: 16px;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.3;
  color: var(--black);
}

.description {
  font-size: 14px;
  color: var(--gray-600);
  line-height: 1.3;
}

@media (max-width: 1024px) {
  .items {
    margin-top: 40px;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 575px) {
  .items {
    grid-template-columns: 1fr;
  }
}
