.button {
  min-height: 34px;
  padding: 8px 16px;
  border-radius: var(--border-radius-s);
  font-size: 15px;
  font-weight: 500;
}

.requestDemoImage {
  height: auto;
  border-radius: var(--border-radius-xs);
  box-shadow: 0px 11.28px 13.54px 0px #00000059,
    0px 0px 9.02px 0px #00000026,
    0px 0px 21.71px 0px #79BE70B2;
}

.media {
  background: var(--white);
}

.marquee {
  --bg: var(--white);
}

@media (max-width: 1280px) {
  .requestDemoImage {
    width: 50%;
  }
}

@media (max-width: 1024px) {
  .requestDemoImage {
    width: 100%;
    margin-bottom: 32px;
  }
}

@media (max-width: 800px) {
  .button {
    width: 100%;
  }
}
