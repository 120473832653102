.items {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(70px, 1fr));
  gap: 8px;
}

.item {
  /* width: 70px; */
  min-height: 34px;
  padding: 6px;
  font-size: 14px;
  font-weight: 400;
  border-radius: var(--border-radius-s);
  box-shadow: inset 0 0 0 1px var(--grey-3);
}

/* .item:hover,
.item:focus {
  box-shadow: inset 0 0 0 1px var(--color-green-primary);
  background: var(--color-green-4);
  color: var(--color-green-primary);
} */

.item:active {
  box-shadow: inset 0 0 0 1px var(--grey-3);
}

.item.selected {
  box-shadow: inset 0 0 0 1px var(--color-green-primary);
  background: var(--color-green-primary);
  color: var(--color-white-primary);
}

.item.disabled {
  background: var(--grey-6);
  color: var(--grey-2);
  pointer-events: none;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
}

@media (max-width: 575px) {}
