.root {
  background: linear-gradient(95.46deg, #FFFFFF 27.18%, #B4DCAF 96.6%);
}

.modalSubtitle {
  margin-bottom: 24px;
  font-size: 18px;
  line-height: 1.28;
  color: var(--grey-0);
  text-align: center;
}

.buttons {
  justify-content: center;
  margin-top: 16px;
}

.button {
  min-height: 40px;
  padding: 6px 16px;
  font-size: 15px;
  font-weight: 500;
}

.notice {
  margin-top: 15px;
  font-size: 12px;
  line-height: 1.2;
  color: var(--grey-2);
  text-align: center;
}

.notice a {
  color: inherit;
  text-decoration: underline;
}

.result {
  max-width: 580px;
  margin: 0 auto;
  text-align: center;
}

.resultSubtitle {
  max-width: 545px;
  margin: 0 auto 24px;
  font-size: 18px;
  text-align: center;
}

@media (max-width: 575px) {
  .modalSubtitle {
    font-size: 14px;
  }

  .notice {
    margin-top: 20px;
    font-size: 12px;
    color: var(--grey-2)
  }

  .result {
    margin-top: 30px;
  }

  .resultSubtitle {
    font-size: 14px;
  }
}
