.root {
  position: relative;
  width: 100%;
  background: var(--grey-4);
  border-radius: var(--border-radius-l);
  line-height: 0;
  overflow: hidden;
}

.loader {
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
}

.playButton {
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 0;
  border: none;
  background: none;
  box-shadow: none;
  transform: translate(-50%, -50%) scale(1);
  transition: transform var(--transition);
  z-index: 1;
}

.playButton:hover {
  transform: translate(-50%, -50%) scale(1.1);
}

.video {
  width: 100%;
  height: 100%;
}

.button {
  min-height: 40px;
  padding: 8px 16px;
  border-radius: var(--border-radius-s);
  font-size: 15px;
  font-weight: 500;
  gap: 8px;
}

.secondary {
  box-shadow: inset 0 0 0 1px var(--grey-3);
}

.secondary:hover,
.secondary:focus-visible {
  color: var(--color-green-primary);
  background: var(--color-green-4);
  box-shadow: inset 0 0 0 1px var(--color-green-primary);
}
